import React from 'react';
import { Link } from 'react-router-dom';

const Technology = () => {
    return (
        <>
            <div className="my-4 py-4" id='technology'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Core Features</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Easy Integration</h4>
                                <p className="text-md font-medium">
                                    Asymptotic's solutions are built with usability in mind and offer a range of gadgets and precompiles
                                    that can be easily integrated into your existing workflow.
                                </p>

                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Lightening-fast</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Asymptotic's lightening-fast proof generation and verification times are unmatched in the industry. You 
                                    can painlessly scale your business without worrying about performance bottlenecks.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Rich Functionality</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Data analytics is far more than just a machine learning model. Asymptotic features a rich set of 
                                    functionalities that can be used to support a wide range of data analytics tasks.
                                </p>
                            </div>
                        </div>

                        {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Expert Support</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Our premium support team is always available to help you with any issues you may encounter.
                                </p>
                            </div>
                        </div>                     */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Technology;